import { SitePermissions, AccountPermissions } from "./Permissions";
export function userHasSitePermission(
  permissions: string[],
  permission: SitePermissions
): boolean {
  return permissions.find(p => p === permission) !== undefined;
}

export function userHasAccountPermission(
  permissions: string[],
  permission: AccountPermissions
): boolean {
  return permissions.find(p => p === permission) !== undefined;
}
