import { AccessCodeGenerationModes } from "../arke-api-typescript";
import {
  AdditionalFieldResponseV1,
  AdditionalFieldType,
} from "./AdditionalFields";
import { VisitStatus } from "./VisitStatus";

export interface ScreeningSettingsResponseV1 {
  screeningEnabled: boolean;
  screeningBeforeVisitTime: string | null;
  questions: ScreeningAdditionalField[];
  screeningAdditionalFields: AdditionalFieldResponseV1[];
}

export class UpdateSiteScreeningSettingsRequestV1 {
  public screeningEnabled!: boolean;
  public screeningBeforeVisitTime!: string | null;
  public questions!: ScreeningAdditionalField[];
}

export class ScreeningAdditionalField {
  public order!: number;
  public additionalFieldId!: string;
  public expectedAnswer!: string;
}

export interface PublicVisitResponseV1 {
  site: SiteData;
  visit: VisitData | null;
  host: HostData | null;
}

export interface HostData {
  name: string;
}

export interface VisitData {
  from: string;
  until: string;
  visitId: string;
  visitStatus: VisitStatus;
  visitorName: string;
  code: string | null;
  screeningOpenedFrom: string | null;
  codeMode: AccessCodeGenerationModes;
}

export interface SiteData {
  siteId: string;
  name: string;
}

export class PublicUpdateVisitRequestV1 {
  visitorFirstName!: string;
  visitorLastName!: string;
}

export class UpdateScreeningQuestionRequestV1 {
  additionalFieldId!: string;
  answer!: string;
}

export interface VisitorScreeningResponseV1 {
  questions: VisitorRegistrationQuestionResponseV1[];
  isInComplete: boolean;
  isSuccess: boolean;
  isFailed: boolean;
}

export interface VisitorRegistrationQuestionResponseV1 {
  additionalFieldId: string;
  order: number;
  description: string;
  additionalFieldType: AdditionalFieldType;
  additionalFieldOptions: string[];
  isComplete: boolean;
}
